import Img from 'gatsby-image'
import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import usePageQuery from '../../pageQuery'
import { BlogCarousel } from './style'

const FiquePorDentro = () => {
  const data = usePageQuery()

  return (
    <section className='bg-gray'>
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6'>
            <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500'>
              <span className='d-md-block d-xl-inline'>Quer ficar por dentro das </span> <span>novidades sobre</span> o Open Finance?
            </h3>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 mb-4 text-grayscale--400'>
              <a
                href='https://blog.inter.co/'
                title='Visite o Blog do Inter'
              >Acompanhe nosso blog
              </a> e entenda mais sobre a nova tendência do mercado financeiro, o Open Finance.
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4 offset-lg-1 px-xl-0'>
            <BlogCarousel className='col-12 p-card mt-md-3 px-lg-0'>
              <article className='col-12 px-0'>
                <Img fluid={data.news.fluid} alt='Ícone Inter Services' />
                img
                <div className='col-12 card pt-2 pb-3 px-4 mt-n5 bg-white'>
                  <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>De olho no mercado</p>
                  <p className='fs-20 lh-25 fw-600 mt-2 mb-2 mt-md-3 mt-xl-2 title-post text-grayscale--500'>O que é Open Finance e o que ele vai mudar no seu dia a dia?</p>
                  <span className='fs-12 lh-15 fw-700 d-block text-grayscale--300'>19/11/2020</span>
                  <p className='fs-16 lh-19 lh-md-19 mb-2 fw-400 mt-3 text-grayscale--400'>Nesse texto vamos falar de um sistema que vai ao encontro de dois pilares do Inter: transparência e simplicidade.</p>
                  <a
                    href='https://blog.inter.co/open-banking'
                    target='_blank'
                    title='Leia mais'
                    className='fs-14 lh-17 fw-400 text-orange--extra float-right mt-xl-3' rel='noreferrer'
                  >
                    Leia mais
                    <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                  </a>
                </div>
              </article>
            </BlogCarousel>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FiquePorDentro
